/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { Formik, Form } from 'formik';
// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import UserInfo from 'layouts/authentication/sign-in/basic/components/SignInForm';
import initialValues from 'layouts/authentication/sign-in/basic/schemas/initialValues';
import validations from 'layouts/authentication/sign-in/basic/schemas/validations';
import form from 'layouts/authentication/sign-in/basic/schemas/form';
import { login, getCurrentUser } from 'services/auth';
// Images
import bgImage from 'assets/images/bg-sign-in-basic.jpeg';
import { setName, setEmail, useMaterialUIController, setTwoFactor } from 'context';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import MDInput from 'components/MDInput';

import { useCookies } from 'react-cookie';

function Basic() {
  const [, dispatch] = useMaterialUIController();
  const [isTwoFactor, setIsTwoFactor] = useState(false);
  const [code, setCode] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [values, setValues] = useState({ email: '', password: '' });
  const [cookie, setCookie] = useCookies(['access_token']);
  const handleSubmit = (values, actions) =>
    login(values.email, values.password)
      .then((res) => {
        if (res.data.isTwoFactorAuthenticationEnabled) {
          setIsTwoFactor(res.data.isTwoFactorAuthenticationEnabled);
          setValues(values);
          actions.setSubmitting(false);
          actions.resetForm();
        } else {
          setCookie('access_token', res?.data?.access_token, { path: '/' });
          actions.setSubmitting(false);
          actions.resetForm();
          if (res.data.access_token == undefined) {
            setErrorCode('Email or password is incorrect');
          } else {
            getCurrentUser().then((res) => {
              serUserData(res);
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        alert('Email or password is incorrect');
        console.log(err);
        actions.setSubmitting(false);
        actions.resetForm();
      });
  const navigate = useNavigate();

  function serUserData(user) {
    setName(dispatch, `${user.firstName} ${user.lastName}`);
    setEmail(dispatch, user.email);
    setTwoFactor(dispatch, user.isTwoFactorAuthenticationEnabled);
    navigate('/player-management', { replace: true });
  }

  function handleChange(e) {
    const currentCode = e.target.value;

    if (currentCode.length <= 6) {
      setCode(currentCode);

      if (errorCode) {
        setErrorCode('');
      }
    }
  }

  const { formId, formField } = form;
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant='gradient'
          bgColor='info'
          borderRadius='lg'
          coloredShadow='info'
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign='center'
        >
          <MDTypography variant='h4' fontWeight='medium' color='white' mt={1}>
            {'Sign in'}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Grid container justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
            <Grid item xs={12} lg={8}>
              {
                <Formik initialValues={initialValues} validationSchema={validations} onSubmit={handleSubmit}>
                  {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form id={formId} autoComplete='off'>
                      <MDBox>
                        <UserInfo
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                            isSubmitting
                          }}
                        />
                        <MDBox mt={2} width='100%' display='flex' justifyContent='space-between'>
                          <MDButton disabled={isSubmitting} type='submit' variant='gradient' color='info' fullWidth>
                            Sign in
                          </MDButton>
                        </MDBox>
                        <MDBox mt={0.75}>
                          <MDTypography component='div' variant='caption' color='error' fontWeight='regular'>
                            {errorCode}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              }
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
